import React from 'react'

import about from '../../img/website wall 1.png'
import { Link } from 'react-router-dom';


export default function Faq() {
    return (<>
        <div className="container-fluid bg-breadcrumb">
            <ul className="breadcrumb-animation">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
            <div className="container text-center py-5" style={{ maxWidth: '900px' }}>
                <h3 className="display-3 mb-4" data-aos="fade-down" data-aos-delay="100">FAQ</h3>
                <ol className="breadcrumb justify-content-center mb-0" data-aos="fade-down" data-aos-delay="300">
                    <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                    <li className="breadcrumb-item active text-primary">FAQ</li>
                </ol>
            </div>
        </div>
        <div className="container-fluid FAQ bg-light overflow-hidden py-5">
            <div className="container py-5">
                <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '900px' }}>

                    <h1 class="display-5 mb-4">Important frequently asked questions</h1>

                </div>
                <div className="row g-5 align-items-center">
                    <div className="col-lg-6 wow fadeInLeft" data-wow-delay="0.1s">
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item border-0 mb-4">
                                <h2 className="accordion-header" id="headingOne">
                                    <button
                                        className="accordion-button rounded-top"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne"
                                        aria-expanded="true"
                                        aria-controls="collapseTOne"
                                    >
                                        What differentiates your company from other companies ?
                                    </button>
                                </h2>
                                <div
                                    id="collapseOne"
                                    className="accordion-collapse collapse show"
                                    aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body my-2">
                                       <p>
                                            At safe hand, we ensure the protection of our clients' rights and the ownership of their ideas. Our development process focuses on creating the application with the features you want, enhanced by our innovative ideas that contribute to the app's improvement. We maintain constant communication with you even after the app is developed, making necessary adjustments and enhancements to continually make the app better and better.
                                            By choosing safe hand , you are guaranteed a partner who is dedicated to safeguarding your intellectual property, delivering a high-quality product tailored to your needs, and providing ongoing support to ensure your app's continued success.
                                        </p>
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item border-0 mb-4">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button
                                        className="accordion-button collapsed rounded-top"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="collapseTwo"
                                    >
                                        Are there any hidden charges?
                                    </button>
                                </h2>
                                <div
                                    id="collapseTwo"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body my-2">
                                 
                                        <p>
                                        Mostly, there will be no additional expenses unless agreed upon
                                        </p>
                                     
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item border-0 mb-4">
                                <h2 className="accordion-header" id="headingThree">
                                    <button
                                        className="accordion-button collapsed rounded-top"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                    >
                                     What platforms do you develop apps for (iOS, Android, etc.)?
                                    </button>
                                </h2>
                                <div
                                    id="collapseThree"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingThree"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body my-2">
                                       
                                        <p>
                                        we have skilled Flutter developers, which allows us to develop applications for both iOS and Android simultaneously. This cross-platform approach ensures a consistent user experience across both platforms and speeds up the development process.
                                        </p>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item border-0 mb-4">
                                <h2 className="accordion-header" id="Four">
                                    <button
                                        className="accordion-button collapsed rounded-top"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseFour"
                                        aria-expanded="false"
                                        aria-controls="collapseFour"
                                    >
                                     What platforms do you develop apps for (iOS, Android, etc.)?
                                    </button>
                                </h2>
                                <div
                                    id="collapseFour"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="Four"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body my-2">
                                       
                                        <p>
                                        we have skilled Flutter developers, which allows us to develop applications for both iOS and Android simultaneously. This cross-platform approach ensures a consistent user experience across both platforms and speeds up the development process.
                                        </p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 wow fadeInRight" data-wow-delay="0.3s">
                        <div className="FAQ-img RotateMoveRight rounded">
                            <img src={about} className="img-fluid w-100" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}
