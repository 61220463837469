import React, { useEffect, useState } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import {toast} from "react-hot-toast";

import axios from 'axios';

export default function Contact() {
    useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);




  const [buttonText, setButtonText] = useState('Send');
  const [formInitialDetails, setFormInitialDetails] = useState({
      full_Name: "",
      email: "",
      phone: 0,
      service: "",
      message: "",
  });

  const handleSubmit = async (e) => {
      e.preventDefault();
      setButtonText("Sending...");
      await axios.post("https://safehand-mails.vercel.app/mail", formInitialDetails, {
          headers: {
              "Content-Type": "application/json;charset=utf-8",
          }
      }).then(() => {
          setButtonText("Send");
          toast.success("Message sent successfully", { duration: 2000, className: " text-success" });

      }).catch(() => {
          setButtonText("Send");
          toast.error('Something went wrong', { duration: 2000, className: "bg-black text-white" });

      })
  };

  function getData(e) {
      const myData = { ...formInitialDetails };
      myData[e.target.name] = e.target.value;
      setFormInitialDetails(myData);
  }

  return (<>
    <div className="container-fluid bg-breadcrumb">
      <ul className="breadcrumb-animation">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <div className="container text-center py-5" style={{ maxWidth: '900px' }}>
        <h3 className="display-3 mb-4" data-aos="fade-down" data-aos-delay="100">Contact Us</h3>
        <ol className="breadcrumb justify-content-center mb-0" data-aos="fade-down" data-aos-delay="300">
          <li className="breadcrumb-item"><Link href="i/">Home</Link></li>
          <li className="breadcrumb-item active text-primary">Contact</li>
        </ol>
      </div>
    </div>
    <div className="container-fluid contact py-5">
            <div className="container py-5">
                <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: '900px'}}>
                    <h4 className="text-primary mb-4">Contact Us</h4>
                    <h1 className="display-5 mb-4">Get In Touch With Us</h1>
                    
                </div>
                <div className="row g-5 align-items-center">
                    <div className="col-lg-6 wow fadeInLeft" data-wow-delay="0.1s">
                        <h2 className="display-5 mb-2">Our Contact Form</h2>
                      <form  onSubmit={handleSubmit}>
                            <div className="row g-3">
                                <div className="col-lg-12 col-xl-6">
                                    <div className="form-floating">
                                        <input type="text"  onChange={getData}
                                                name="full_Name"
                                                className="form-control" id="name" placeholder="Your Name"/>
                                        <label for="name">Your Name</label>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-xl-6">
                                    <div className="form-floating">
                                        <input type="email"   onChange={getData}  name="email" className="form-control" id="email" placeholder="Your Email"/>
                                        <label for="email">Your Email</label>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-xl-6">
                                    <div className="form-floating">
                                        <input type="phone"  onChange={getData} name="phone" className="form-control" id="phone" placeholder="Phone"/>
                                        <label for="phone">Your Phone</label>
                                    </div>
                                </div>
                             
                                <div className="col-12">
                                    <div className="form-floating">
                                        <input type="text"  onChange={getData}  name="service" className="form-control" id="subject" placeholder="Subject"/>
                                        <label for="subject">Subject</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating">
                                        <textarea className="form-control"  onChange={getData}  name="message" placeholder="Leave a message here" id="message" style={{height: '160px'}}></textarea>
                                        <label for="message">Message</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                <button
                                                className="btn btn-primary w-100 py-3"
                                                type="submit"
                                            >
                                                {buttonText}
                                            </button>

                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-lg-6 wow fadeInRight" data-wow-delay="0.3s">
                        <div className="d-flex align-items-center mb-4">
                            <div className="bg-light d-flex align-items-center justify-content-center mb-3" style={{width: '50px',height: '50px', borderRadius: '50px'}}><i className="fa fa-home fa-2x text-primary"></i></div>
                            <div className="ms-4">
                                <h4>Addresses</h4>
                                <p className="mb-0"> بني سويف شارع خالد عبد العزيز المتفرع من شارع الاباصيري
                                </p>
                            </div>
                        </div>
                    
                        <div className="d-flex align-items-center mb-4">
                            <div className="bg-light d-flex align-items-center justify-content-center mb-3" style={{width: '50px',height: '50px', borderRadius: '50px'}}><i className="fa fa-phone-alt fa-2x text-primary"></i></div>
                            <div className="ms-4">
                                <h4>Mobile</h4>
                                <p className="mb-0">01004010555  </p>
                               
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                            <div className="bg-light d-flex align-items-center justify-content-center mb-3" style={{width: '50px',height: '50px', borderRadius: '50px'}}><i className="fa fa-envelope-open fa-2x text-primary"></i></div>
                            <div className="ms-4">
                                <h4>Email</h4>
                                <p className="mb-0">Safehandapps@gmail.com</p>
                            
                            </div>
                        </div>
                     
                    </div>
                    <div className="col-12 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="rounded h-100">
                            <iframe 
                            className="rounded w-100" 
                            style={{height: '500px'}} 
                            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1232.9168740659459!2d31.104413583911608!3d29.069775431759687!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjnCsDA0JzEwLjUiTiAzMcKwMDYnMTcuNSJF!5e0!3m2!1sen!2seg!4v1721066553247!5m2!1sen!2seg"
                            loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                       
                        </div>
                        </div>
                </div>
            </div>
        </div>

  </>);
};




 
