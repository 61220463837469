import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../img/logo safe hand.png'
const Navbar = () => {
    return (
        <div className="container-fluid header position-relative overflow-hidden p-0 ">
            <nav className="navbar navbar-expand-lg sticky-top shadow shadow-2 navbar-light px-4 px-lg-5 py-3 py-lg-0">
                <Link to="/" className="navbar-brand p-0">
                    <img src={logo}  />
                </Link>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span class="fa fa-bars"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto py-0">
                        <Link to="/" className="nav-item nav-link ">Home</Link>
                        <Link to="/about" className="nav-item nav-link">About</Link>
                        <Link to="/service" className="nav-item nav-link">Services</Link>
                        <Link to="/contact" className="nav-item nav-link">Contact Us</Link>
                        <Link to="/faq" className="nav-item nav-link">FAQ</Link>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Navbar
