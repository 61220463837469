import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

export default function Footer() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);

  return (
    <div
      className="container-fluid footer py-5"
      data-aos="fade-in"
      data-aos-delay="200"
    >
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-md-6 col-lg-6 col-xl-4">
            <div className="footer-item d-flex flex-column">
              <h4 className="text-dark mb-4">Company</h4>
              <Link to="">Our Portfolio</Link>
              <Link to="/about">About Us</Link>

              <Link to="/contact">Contact us</Link>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-4">
            <div className="footer-item d-flex flex-column">
              <h4 className="mb-4 text-dark">Quick Links</h4>
              <Link to="/about">About Us</Link>
              <Link to="/faq">Our Blog & News</Link>
              <Link to="/about">Our Team</Link>
            </div>
          </div>

          <div className="col-md-6 col-lg-6 col-xl-4">
            <div className="footer-item d-flex flex-column">
              <h4 className="mb-4 text-dark">Contact Info</h4>
              <Link to="">
                <i className="fa fa-map-marker-alt me-2"></i>
                بني سويف شارع خالد عبد العزيز المتفرع من شارع الاباصيري
              </Link>
              <Link to="">
                <i className="fas fa-envelope me-2"></i> Safehandapps@gmail.com
              </Link>
              <Link to="">
                <i className="fas fa-phone me-2"></i> 01004010555
              </Link>

              <div className="d-flex align-items-center mt-2">
                <link
                  className="fas fa-share fa-2x text-secondary me-2"
                  to="https://www.facebook.com/@safehandapps/?mibextid=ZbWKwL"
                ></link>
                <Link
                  className="btn-square btn btn-primary rounded-circle mx-1  center-icon "
                  to="https://www.facebook.com/@safehandapps/?mibextid=ZbWKwL"
                >
                  <i className="fab fa-facebook-f "></i>
                </Link>
                <Link
                  className="btn-square btn btn-primary rounded-circle mx-1 center-icon"
                  to=""
                >
                  <i className="fab fa-linkedin-in"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
