import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import Faq from '../faq/Faq.jsx';

export default function Services() {
      useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return <>
    <div className="container-fluid bg-breadcrumb">
      <ul className="breadcrumb-animation">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <div className="container text-center py-5" style={{ maxWidth: '900px' }}>
        <h3 className="display-3 mb-4" data-aos="fade-down" data-aos-delay="100">Our Services</h3>
        <ol className="breadcrumb justify-content-center mb-0" data-aos="fade-down" data-aos-delay="300">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>
          <li className="breadcrumb-item active text-primary">Service</li>
        </ol>
      </div>
    </div>


    <div className="container-fluid service py-5">
        <div className="container py-5">
          <div
            className="text-center mx-auto mb-5"
            data-aos="fade-up"
            data-aos-delay="100"
            style={{ maxWidth: '900px' }}
          >
            <h4 className="mb-1 text-primary">Our Service</h4>
            <h1 className="display-5 mb-4">What We Can Do For You</h1>
            <p className="mb-0">
            we specialize in creating, developing, and managing top-tier mobile applications tailored to your needs. Our services encompass every stage of the app lifecycle, from initial concept and design to development, deployment, and ongoing management. We ensure your app not only meets but exceeds user expectations, providing seamless functionality, cutting-edge features, and a superior user experience. Trust us to transform your vision into a powerful, user-friendly app that drives engagement and growth for your business
         
            </p>
          </div>
          <div className="row g-4 justify-content-center">
            <div className="col-md-6 col-lg-4 col-xl-3" data-aos="fade-up" data-aos-delay="100">
              <div className="service-item text-center rounded p-4">
                <div className="service-icon d-inline-block bg-light rounded p-4 mb-4">
                  <i className="fas fa-mail-bulk fa-5x text-secondary"></i>
                </div>
                <div className="service-content">
                  <h4 className="mb-4"> Innovation</h4>
                  <p className="mb-4">
                  We strive to stay ahead of the curve, constantly exploring new ideas and technologies to bring groundbreaking apps to life.

                  </p>
                  
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4 col-xl-3" data-aos="fade-up" data-aos-delay="300">
              <div className="service-item text-center rounded p-4">
                <div className="service-icon d-inline-block bg-light rounded p-4 mb-4">
                  <i className="fas fa-thumbs-up fa-5x text-secondary"></i>
                </div>
                <div className="service-content">
                  <h4 className="mb-4"> Quality</h4>
                  <p className="mb-4">
                  Excellence is at the heart of everything we do. We are committed to delivering top-tier apps that are reliable, secure, and user-friendly.

                  </p>
                  
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4 col-xl-3" data-aos="fade-up" data-aos-delay="500">
              <div className="service-item text-center rounded p-4">
                <div className="service-icon d-inline-block bg-light rounded p-4 mb-4">
                  <i className="fa fa-subway fa-5x text-secondary"></i>
                </div>
                <div className="service-content">
                  <h4 className="mb-4">User-Centric</h4>
                  <p className="mb-4">
                   Our users are our top priority. We design and develop apps with a deep understanding of their needs, ensuring a seamless and enjoyable experience.

                  </p>
                 
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4 col-xl-3" data-aos="fade-up" data-aos-delay="700">
              <div className="service-item text-center rounded p-4">
                <div className="service-icon d-inline-block bg-light rounded p-4 mb-4">
                  <i className="fas fa-sitemap fa-5x text-secondary"></i>
                </div>
                <div className="service-content">
                  <h4 className="mb-4"> Integrity</h4>
                  <p className="mb-4">
                  We uphold the highest standards of integrity and transparency in all our interactions and business practices.

                  </p>
                  
                </div>
              </div>
            </div>
          
       
          </div>
        </div>
      </div>

    </>
};

