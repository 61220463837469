import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import aboutImage from '../../img/website wall 21.png';
import blog2 from '../../img/02 - Splash 3.jpg'
import blog3 from '../../img/02 - Splash 4.jpg'

import { Link } from 'react-router-dom';

const About = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      <div className="container-fluid bg-breadcrumb">
        <ul className="breadcrumb-animation">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <div className="container text-center py-5" style={{ maxWidth: '900px' }}>
          <h3 className="display-3 mb-4" data-aos="fade-down" data-aos-delay="100">About Us</h3>
          <ol className="breadcrumb justify-content-center mb-0" data-aos="fade-down" data-aos-delay="300">
            <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
            <li className="breadcrumb-item active text-primary">About</li>
          </ol>
        </div>
      </div>

   
      <div className="container-fluid overflow-hidden py-5" style={{ marginTop: '6rem' }}>
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
            <div className="RotateMoveLeft">
              <img src={aboutImage} className="img-fluid w-100 rounded-circle border border-radius shadow shadow-2" alt="" />
            </div>
          </div>
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="300">
            <h4 className="mb-1 text-primary">About Us</h4>
            <h1 className="display-5 mb-4">Get Started </h1>
            <p className="mb-4">
              Welcome to Save Hand Apps, where innovation meets ambition. At Save Hand Apps, we are dedicated to creating and developing cutting-edge mobile applications
              designed to enhance everyday life and solve real-world problems. Founded with a vision to revolutionize the digital landscape, our team of passionate and talented professionals is committed to delivering high-quality, user-centric solutions.

            </p>
            <Link to="/about" className="btn btn-primary rounded-pill py-3 px-5">
              About More
            </Link>
          </div>
        </div>
      </div>
    </div>
     {/* ////////////////////////////////////////////Products///////////////////////////////////////////////////////////// */}

    
     <div className="container-fluid blog py-5">
      <div className="container py-5">
        <div
          className="text-center mx-auto mb-5"
          data-aos="fade-up"
          data-aos-delay="100"
          style={{ maxWidth: '900px' }}
        >
          <h4 className="text-primary">Our Products</h4>
          <p className="mb-0">
          stablish a strong presence in the Egyptian market while expanding our reach to international markets, showcasing Egyptian talent on a global stage.
          </p>
        </div>
        <div className="row g-4 justify-content-center">
          <div className="col-md-6 col-lg-4 col-xl-3" data-aos="fade-up" data-aos-delay="100">
            <div className="blog-item">
              <div className="blog-img">
                <img src={blog2} className="img-fluid w-100" alt="Blog 1" />
               
              </div>
              <div className="blog-content text-dark border p-4">
                <h2 className=" text-primary mb-4"> Cavello </h2>
                <p className="mb-4">Our restaurant application streamlines order management, allows users to easily place orders and book tables, and facilitates efficient delivery services.</p>
              
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-xl-3" data-aos="fade-up" data-aos-delay="300">
            <div className="blog-item">
              <div className="blog-img">
                <img src={blog3} className="img-fluid w-100" alt="Blog 2" />
             
              </div>
              <div className="blog-content text-dark border p-4">
                <h2 className=" text-primary mb-4">Clinic</h2>
                <p className="mb-4">Our clinic management app streamlines operations and enhances patient-doctor interactions with tools for scheduling, records, and secure chat communication.</p>
           
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default About;

