import React, { useEffect } from "react";
import img from "../../img/sty-1.png";
import img2 from "../../img/website wallpaper.png";
import img3 from "../../img/website wall 3_Mesa de trabajo 1.png";

import blog2 from "../../img/02 - Splash 3.jpg";
import blog3 from "../../img/02 - Splash 4.jpg";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
export default function Home() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);
  return (
    <>
      {/* ////////////////////////////////////////////Hero///////////////////////////////////////////////////////////// */}

      <div className="hero-header overflow-hidden px-5">
        <div className="rotate-img">
          <img src={img} className="img-fluid w-100" alt="" />
          <div className="rotate-sty-2"></div>
        </div>
        <div className="row gy-5 align-items-center">
          <div className="col-lg-6" data-aos="fade-left" data-aos-delay="100">
            <h1
              className="display-4 text-dark mb-4"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              You Are In Safe Hand
            </h1>
            <p className="fs-4 mb-4" data-aos="fade-up" data-aos-delay="500">
              we specialize in creating innovative and user-friendly mobile
              applications tailored to meet your business needs. Our expert team
              of developers harnesses the latest technologies to deliver
              high-quality, customized apps that drive engagement and enhance
              your digital presence
            </p>
            <Link
              to=""
              className="btn btn-primary rounded-pill py-3 px-5"
              data-aos="fade-up"
              data-aos-delay="700"
            >
              Get Started
            </Link>
          </div>
          <div className="col-lg-6" data-aos="fade-right" data-aos-delay="200">
            <img src={img2} className="img-fluid w-100 h-100" alt="" />
          </div>
        </div>
      </div>

      {/* ////////////////////////////////////////////about///////////////////////////////////////////////////////////// */}

      <div
        className="container-fluid overflow-hidden py-5"
        style={{ marginTop: "6rem" }}
      >
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
              <div className="RotateMoveLeft">
                <img src={img3} className="img-fluid w-100" alt="" />
              </div>
            </div>
            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="300">
              <h4 className="mb-1 text-primary">About Us</h4>
              <h1 className="display-5 mb-4">Get Started </h1>
              <p className="mb-4">
                Welcome to safe Hand Apps, where innovation meets ambition. At
                Safe Hand Apps, we are dedicated to creating and developing
                cutting-edge mobile applications designed to enhance everyday
                life and solve real-world problems. Founded with a vision to
                revolutionize the digital landscape, our team of passionate and
                talented professionals is committed to delivering high-quality,
                user-centric solutions.
              </p>
              <Link
                to="/about"
                className="btn btn-primary rounded-pill py-3 px-5"
              >
                About More
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* //////////////////////////////////////////////////services/////////////////////////////////////////////////////// */}

      <div className="container-fluid service py-5">
        <div className="container py-5">
          <div
            className="text-center mx-auto mb-5"
            data-aos="fade-up"
            data-aos-delay="100"
            style={{ maxWidth: "900px" }}
          >
            <h4 className="mb-1 text-primary">Our Service</h4>
            <h1 className="display-5 mb-4">What We Can Do For You</h1>
            <p className="mb-0">
              we specialize in creating, developing, and managing top-tier
              mobile applications tailored to your needs. Our services encompass
              every stage of the app lifecycle, from initial concept and design
              to development, deployment, and ongoing management. We ensure your
              app not only meets but exceeds user expectations, providing
              seamless functionality, cutting-edge features, and a superior user
              experience. Trust us to transform your vision into a powerful,
              user-friendly app that drives engagement and growth for your
              business
            </p>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-md-6 col-lg-4 col-xl-3"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="service-item text-center rounded p-4">
                <div className="service-icon d-inline-block bg-light rounded p-4 mb-4">
                  <i className="fas fa-mail-bulk fa-5x text-secondary"></i>
                </div>
                <div className="service-content">
                  <h4 className="mb-4"> Innovation</h4>
                  <p className="mb-4">
                    We strive to stay ahead of the curve, constantly exploring
                    new ideas and technologies to bring groundbreaking apps to
                    life.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-md-6 col-lg-4 col-xl-3"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="service-item text-center rounded p-4">
                <div className="service-icon d-inline-block bg-light rounded p-4 mb-4">
                  <i className="fas fa-thumbs-up fa-5x text-secondary"></i>
                </div>
                <div className="service-content">
                  <h4 className="mb-4"> Quality</h4>
                  <p className="mb-4">
                    Excellence is at the heart of everything we do. We are
                    committed to delivering top-tier apps that are reliable,
                    secure, and user-friendly.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-md-6 col-lg-4 col-xl-3"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <div className="service-item text-center rounded p-4">
                <div className="service-icon d-inline-block bg-light rounded p-4 mb-4">
                  <i className="fa fa-subway fa-5x text-secondary"></i>
                </div>
                <div className="service-content">
                  <h4 className="mb-4">User-Centric</h4>
                  <p className="mb-4">
                    Our users are our top priority. We design and develop apps
                    with a deep understanding of their needs, ensuring a
                    seamless and enjoyable experience.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-md-6 col-lg-4 col-xl-3"
              data-aos="fade-up"
              data-aos-delay="700"
            >
              <div className="service-item text-center rounded p-4">
                <div className="service-icon d-inline-block bg-light rounded p-4 mb-4">
                  <i className="fas fa-sitemap fa-5x text-secondary"></i>
                </div>
                <div className="service-content">
                  <h4 className="mb-4"> Integrity</h4>
                  <p className="mb-4">
                    We uphold the highest standards of integrity and
                    transparency in all our interactions and business practices.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*///////////////////////////////////////////////////feature//////////////////////////////////////////////////*/}
      <div className="container-fluid feature overflow-hidden py-5">
        <div className="container py-5">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "900px" }}
          >
            <h4 className="text-primary">Our Feature</h4>
            <p className="mb-0">
              Our mission is to empower individuals and businesses through
              technology, providing intuitive and reliable apps that simplify
              tasks, improve productivity, and foster connectivity. From idea to
              execution, we ensure that each of our applications is crafted with
              precision, creativity, and a deep understanding of user needs.
            </p>
          </div>
          <div className="row g-4 justify-content-center text-center mb-5">
            <div
              className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="text-center p-4">
                <div className="d-inline-block rounded bg-light p-4 mb-4">
                  <i className="fas fa-envelope fa-5x text-secondary"></i>
                </div>
                <div className="feature-content">
                  <a href="#" className="h4">
                    Our Vision
                    <i className="fa fa-long-arrow-alt-right"></i>
                  </a>
                  <p className="mt-4 mb-0">
                    To be a leading force in mobile app innovation, consistently
                    delivering solutions that exceed expectations and drive
                    positive change in the digital era.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="text-center p-4">
                <div className="d-inline-block rounded bg-light p-4 mb-4">
                  <i className="fas fa-mail-bulk fa-5x text-secondary"></i>
                </div>
                <div className="feature-content">
                  <a href="#" className="h4">
                    Our Mission
                    <i className="fa fa-long-arrow-alt-right"></i>
                  </a>
                  <p className="mt-4 mb-0">
                    To create exceptional mobile applications that enhance
                    everyday life, empower users, and drive technological
                    advancement through creativity and quality.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="text-center rounded p-4">
                <div className="d-inline-block rounded bg-light p-4 mb-4">
                  <i className="fas fa-sitemap fa-5x text-secondary"></i>
                </div>
                <div className="feature-content">
                  <a href="#" className="h4">
                    Our Values
                    <i className="fa fa-long-arrow-alt-right"></i>
                  </a>
                  <p className="mt-4 mb-0">
                    Innovation: We strive to stay ahead of the curve, constantly
                    exploring new ideas and technologies to bring groundbreaking
                    apps to life.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ////////////////////////////////////////////Products///////////////////////////////////////////////////////////// */}

      <div className="container-fluid blog py-5">
        <div className="container py-5">
          <div
            className="text-center mx-auto mb-5"
            data-aos="fade-up"
            data-aos-delay="100"
            style={{ maxWidth: "900px" }}
          >
            <h4 className="text-primary">Our Products</h4>

            <p className="mb-0">
              stablish a strong presence in the Egyptian market while expanding
              our reach to international markets, showcasing Egyptian talent on
              a global stage.
            </p>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-md-6 col-lg-4 col-xl-3"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="blog-item">
                <div className="blog-img">
                  <img src={blog2} className="img-fluid w-100" alt="Blog 1" />
                </div>
                <div className="blog-content text-dark border p-4">
                  <h2 className=" text-primary mb-4"> Cavello </h2>
                  <p className="mb-4">
                    Our restaurant application streamlines order management,
                    allows users to easily place orders and book tables, and
                    facilitates efficient delivery services.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 col-xl-3"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="blog-item">
                <div className="blog-img">
                  <img src={blog3} className="img-fluid w-100" alt="Blog 2" />
                </div>
                <div className="blog-content text-dark border p-4">
                  <h2 className=" text-primary mb-4">Clinic</h2>
                  <p className="mb-4">
                    Our clinic management app streamlines operations and
                    enhances patient-doctor interactions with tools for
                    scheduling, records, and secure chat communication.
                  </p>
                </div>
              </div>
            </div>
            {/* =================================================== */}
          </div>
        </div>
      </div>
    </>
  );
}
